import {get, post} from "./http"

function OptOutAPI() {
	function find(id, channel) {
		return get(`/app/opt-out/${id}/${channel}`)
	}

	function unsubscribe({token, channel}) {
		return post(`/app/opt-out/${token}/${channel}`, {})
	}

	return {find, unsubscribe}
}

export {OptOutAPI}
