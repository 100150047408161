import {post} from "./http"

function TransactionalEmailOptOutAPI() {
	function transactionalEmailOptOut({token, emailAddress, emailSentAt, templateTypeName}) {
		return post(`/app/transactional-email-opt-out/${token}`, {
			EmailAddress: emailAddress,
			EmailSentAt: emailSentAt,
			TemplateTypeName: templateTypeName,
		})
	}

	return {transactionalEmailOptOut}
}

export {TransactionalEmailOptOutAPI}
