import {get, post} from "./http"

function CustomerAPI() {
	function find({secureCode}) {
		return get(`/app/customers/${secureCode}`, {})
	}

	function status({secureCode}) {
		return get(`/app/customers/${secureCode}`, {})
	}

	function grantESIGNConsent({customerID}) {
		return post(`/app/customer/${customerID}/grant-esign-consent`, {})
	}

	function requestESIGNConsentForm({customerID}) {
		return get(`/app/customer/${customerID}/grant-esign-consent`, {})
	}

	return {find, status, grantESIGNConsent, requestESIGNConsentForm}
}

export {CustomerAPI}
