import {get, post} from "./http"

function PaymentPlanAPI() {
	function pollConfirmation(paymentPlanID) {
		return new Promise((resolve, reject) => {
			var numberOfTries = 0

			const intervalId = setInterval(() => {
				get(`/app/payment-plans-status/${paymentPlanID}`)
					.then((result) => {
						if (
							(result.Status !== "Initiated" && result.Status != "IntentSubmitted") ||
							numberOfTries === 59
						) {
							clearInterval(intervalId)
							resolve(result)
						}
						numberOfTries++
					})
					.catch((e) => {
						clearInterval(intervalId)
						reject(e)
					})
			}, 1000)
		})
	}

	function create({secureCode, paymentMethod, paymentPlanFrequency, instalmentAmount, payDay, startDate, aaid}) {
		return post(`/app/payment-plans/${secureCode}`, {
			PaymentMethod: paymentMethod,
			PaymentPlanFrequency: paymentPlanFrequency,
			InstalmentAmount: instalmentAmount,
			PayDay: payDay,
			StartDate: startDate,
			AffordabilityAssessmentID: aaid,
		})
	}

	function createForm({secureCode}) {
		return get(`/app/payment-plans/${secureCode}`)
	}

	function getAmountPage({secureCode}) {
		return get(`/app/payment-plans/${secureCode}/amount`)
	}

	function getTermsOfDiscountOffer({secureCode}) {
		return get(`/app/payment-plans/${secureCode}/terms-of-discount-offer`)
	}

	function receiveIntentSubmitted({paymentPlanId, providerName, providerRawResponse}) {
		return post(`/app/payment-plan/${paymentPlanId}/receive-intent-submitted`, {
			ProviderName: providerName,
			ProviderRawResponse: providerRawResponse,
		})
	}

	function payInstalment({paymentPlanId, instalmentId}) {
		return post(`/app/payment-plan/${paymentPlanId}/pay-instalment`, {
			InstalmentID: instalmentId,
		})
	}

	function settlePaymentPlan({paymentPlanId}) {
		return post(`/app/payment-plan/${paymentPlanId}/initiate-settlement`)
	}

	function pollPaymentPlanStatus(paymentPlanId) {
		return new Promise((resolve, reject) => {
			const intervalId = setInterval(() => {
				pollConfirmation(paymentPlanId)
					.then((result) => {
						if (result.SettlementInProgress === false) {
							clearInterval(intervalId)
							resolve(result)
						}
					})
					.catch((e) => {
						clearInterval(intervalId)
						reject(e)
					})
			}, 1000)
		})
	}

	function pollPayInstalmentConfirmation(paymentPlanId) {
		return new Promise((resolve, reject) => {
			const intervalId = setInterval(() => {
				get(`/app/payment-plan/${paymentPlanId}/instalment-status`)
					.then((result) => {
						if (result.Status !== "Initiated") {
							clearInterval(intervalId)
							resolve(result)
						}
					})
					.catch((e) => {
						clearInterval(intervalId)
						reject(e)
					})
			}, 1000)
		})
	}

	function cancel(paymentPlanId) {
		return post(`/app/payment-plan/${paymentPlanId}/cancel`, {})
	}

	return {
		pollConfirmation,
		create,
		createForm,
		getAmountPage,
		getTermsOfDiscountOffer,
		receiveIntentSubmitted,
		payInstalment,
		pollPayInstalmentConfirmation,
		cancel,
		settlePaymentPlan,
		pollPaymentPlanStatus,
	}
}

export {PaymentPlanAPI}
