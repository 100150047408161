class Region {
	constructor(apiURL, name, region, regionNumber) {
		if (apiURL === undefined) {
			throw new Error("API_URL_US is not set")
		}
		this.apiURL = apiURL
		this.region = region
		this.regionName = name
		this.regionNumber = regionNumber
	}

	url(endpoint) {
		return `${this.apiURL}${endpoint}`
	}

	awsRegion() {
		return this.region
	}

	name() {
		return this.regionName
	}

	number() {
		return this.regionNumber
	}
}

const RegionNumberMap = {
	0: "AU",
	1: "US",
}

const RegionAWSNames = {
	AU: "ap-southeast-2",
	US: "us-west-2",
}

const Regions = {
	AU: new Region(process.env.API_URL_AU, "AU", "ap-southeast-2", 0),
	US: new Region(process.env.API_URL_US, "US", "us-west-2", 1),
}

class RegionManager {
	constructor(region) {
		this.region = region
	}

	setRegionFromRegionNumber(regionNumber) {
		this.region = Regions[RegionNumberMap[regionNumber]]
	}

	setRegionFromAWSRegion(awsRegion) {
		if (awsRegion === RegionAWSNames.US) {
			this.region = Regions.US
		} else {
			this.region = Regions.AU
		}
	}

	setRegionFromSecureCode(secureCode) {
		if (secureCode.length === 11 && secureCode[0] === "1") {
			this.region = Regions.US
		} else {
			this.region = Regions.AU
		}
	}

	getRegion() {
		return this.region.name()
	}

	getRegionAWSName() {
		return this.region.awsRegion()
	}

	url(endpoint) {
		return this.region.url(endpoint)
	}
}

const regionManager = new RegionManager(Regions.AU)

const setRegionFromSecureCode = regionManager.setRegionFromSecureCode.bind(regionManager)
const setRegionFromAWSRegion = regionManager.setRegionFromAWSRegion.bind(regionManager)
const setRegionFromRegionNumber = regionManager.setRegionFromRegionNumber.bind(regionManager)
const getRegion = regionManager.getRegion.bind(regionManager)
const getRegionAWSName = regionManager.getRegionAWSName.bind(regionManager)

export {
	setRegionFromAWSRegion,
	setRegionFromSecureCode,
	setRegionFromRegionNumber,
	getRegion,
	getRegionAWSName,
	Regions,
	RegionNumberMap,
	regionManager,
}
