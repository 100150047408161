import {get} from "./http"

function NotificationOfAssignmentAcknowledgementAPI() {
	function acknowledge({ids, customerId}) {
		return get(`/app/accounts/ack-noa?ids=${ids}&customer-id=${customerId}`)
	}

	return {acknowledge}
}

export {NotificationOfAssignmentAcknowledgementAPI}
