import {AffordabilityAssessmentAPI} from "./affordabilityAssessmentAPI"
import {PaymentAPI} from "./paymentAPI"
import {PartialPaymentAPI} from "./partialPaymentAPI"
import {PaymentPlanAPI} from "./paymentPlanAPI"
import {CustomerAPI} from "./customerAPI"
import {MetadataAPI} from "./metadataAPI"
import {OptOutAPI} from "./optOutAPI"
import {NotificationOfAssignmentAcknowledgementAPI} from "./notificationOfAssignmentAcknowledgementAPI"
import {TransactionalEmailOptOutAPI} from "./transactionalEmailOptOutAPI"
import {
	setRegionFromAWSRegion,
	setRegionFromSecureCode,
	setRegionFromRegionNumber,
	getRegionAWSName,
	Regions,
	RegionNumberMap,
	regionManager,
} from "./regionManager"

const affordabilityAssessmentAPI = AffordabilityAssessmentAPI()
const paymentAPI = PaymentAPI()
const partialPaymentAPI = PartialPaymentAPI()
const paymentPlanAPI = PaymentPlanAPI()
const customerAPI = CustomerAPI()
const metadataAPI = MetadataAPI()
const optOutAPI = OptOutAPI()
const notificationOfAssignmentAcknowledgementAPI = NotificationOfAssignmentAcknowledgementAPI()
const transactionalEmailOptOutAPI = TransactionalEmailOptOutAPI()

export {
	paymentAPI,
	partialPaymentAPI,
	paymentPlanAPI,
	customerAPI,
	metadataAPI,
	affordabilityAssessmentAPI,
	optOutAPI,
	notificationOfAssignmentAcknowledgementAPI,
	transactionalEmailOptOutAPI,
	setRegionFromAWSRegion,
	setRegionFromSecureCode,
	setRegionFromRegionNumber,
	getRegionAWSName,
	Regions,
	RegionNumberMap,
	regionManager,
}
