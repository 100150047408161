import {get, post} from "./http"

function AffordabilityAssessmentAPI() {
	function create({paymentOptionType, secureCode}) {
		return post(`/app/affordability-assessment/${paymentOptionType}/${secureCode}`)
	}

	function submitForm({answers, secureCode, id}) {
		return post(`/app/affordability-assessment/${secureCode}/submit/${id}`, answers)
	}

	function findBy({secureCode, id}) {
		return get(`/app/affordability-assessment/${secureCode}/${id}`)
	}

	return {create, submitForm, findBy}
}

export {AffordabilityAssessmentAPI}
