import {get} from "./http"

function MetadataAPI() {
	function load({secureCode}) {
		return get(`/app/metadata/${secureCode}`, {})
	}

	return {load}
}

export {MetadataAPI}
